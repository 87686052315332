import Resizer from "react-image-file-resizer"
import moment from "moment"
import {
  GATSBY_PBM_ZENDESK_EMAIL,
  GATSBY_PBM_ZENDESK_SUBDOMAIN,
  GATSBY_PBM_ZENDESK_API_KEY,
} from "gatsby-env-variables"
import { zendeskUploadFiles } from "../../../services/zendeskService"
import { generateUploadFiles } from "../../../services/zendeskService"

const MINIMUM_SIZE_TO_COMPRESS = 1048576

const generateFileName = (path, prefix, index) => {
  return `${prefix || "doc"}_${moment().format("YYYYMMDD_HHmmss")}${
    index + 1 ? `_${index + 1}` : "0"
  }`
}

export const isPdf = (imgData) => {
  return (
    imgData?.substring("data:".length, imgData.indexOf(";base64")) ===
    "application/pdf"
  )
}

export const checkIfUploadDisabled = (flow, state) => {
  // const hasNoDocuments = state.documents.length === 0
  switch (flow) {
    case "medensure":
    case "epharmacy":
      return false
    case "flexmed":
      return false

    default:
      return !state.orderingFor
  }
}

export const isAlreadyUploaded = (file, filesUploaded) => {
  return filesUploaded.some((uploaded) => file.oldname === uploaded.oldname)
}

export const uploadDocument = (
  file,
  filesUploaded,
  setFilesUploaded,
  callback,
  alreadyUploadedCallback,
  prefix,
  state,
  dispatch,
  index,
  moduleDispatch,
  fmr
) => {
  let pbmZendeskConfig = {
    email: GATSBY_PBM_ZENDESK_EMAIL,
    apiKey: GATSBY_PBM_ZENDESK_API_KEY,
    apiUrl: GATSBY_PBM_ZENDESK_SUBDOMAIN,
  }
  let reader = new FileReader()
  if (file.type === "application/pdf") {
    reader.readAsDataURL(file)
    reader.onload = () => {
      const newFile = {
        path: reader.result,
        name: generateFileName(file.type.split("/")[1], prefix, index),
        filename: generateFileName(file.type.split("/")[1], prefix, index),
        oldname: file.name,
        size: file.size,
      }
      if (isAlreadyUploaded(newFile, filesUploaded) && alreadyUploadedCallback)
        alreadyUploadedCallback(newFile)
      else {
        setFilesUploaded((fileList) => {
          let previousFilesUploaded = [...fileList]
          previousFilesUploaded.push(newFile)
          if (fmr !== "encode")
            moduleDispatch({
              type: "SAVE_DOCUMENTS",
              payload: [...previousFilesUploaded],
            })
          // moduleDispatch({
          //   type: "SAVE_CONTEXT_TO_SESSION",
          //   payload: {
          //     ...state,
          //     documents: [...previousFilesUploaded],
          //   },
          // })
          // if (callback) callback(previousFilesUploaded)
          return previousFilesUploaded
        })
        setTimeout(async () => {
          const UPLOADED_FILES = await zendeskUploadFiles(
            [newFile],
            null,
            pbmZendeskConfig
          )
          const GENERATED_FILE_TOKEN = await generateUploadFiles(UPLOADED_FILES)
          setFilesUploaded((fileList) => {
            let previousFilesUploaded = [...fileList]
            let newFileList = [
              ...previousFilesUploaded.filter(
                (fileInFileList) => fileInFileList.oldname !== newFile.oldname
              ),
              {
                ...newFile,
                path: null, // Note: this removes the path from the file after uploading to Zendesk
                token: GENERATED_FILE_TOKEN[0],
              },
            ]
            if (fmr !== "encode")
              moduleDispatch({
                type: "SAVE_DOCUMENTS",
                payload: [...newFileList],
              })
            // moduleDispatch({
            //   type: "SAVE_CONTEXT_TO_SESSION",
            //   payload: {
            //     ...state,
            //     documents: [...newFileList],
            //   },
            // })
            // if (callback) callback(newFileList)
            return newFileList
          })

          dispatch({
            type: "SHOW_TOAST",
            payload: {
              message: `${newFile?.oldname} has been uploaded.`,
              color: "success",
            },
          })
        }, 1000)
      }
    }
  } else
    Resizer.imageFileResizer(
      file,
      1000,
      1000,
      "JPEG",
      100,
      0,
      (uri) => {
        const newFile = {
          path: uri,
          name: generateFileName(file.type.split("/")[1], prefix, index),
          filename: generateFileName(file.type.split("/")[1], prefix, index),
          oldname: file.name,
        }
        if (
          isAlreadyUploaded(newFile, filesUploaded) &&
          alreadyUploadedCallback
        )
          alreadyUploadedCallback(newFile)
        else {
          setFilesUploaded((fileList) => {
            let previousFilesUploaded = [...fileList]
            previousFilesUploaded.push(newFile)
            if (fmr !== "encode")
              moduleDispatch({
                type: "SAVE_DOCUMENTS",
                payload: [...previousFilesUploaded],
              })
            // if (callback) callback(previousFilesUploaded)
            return previousFilesUploaded
          })
          setTimeout(async () => {
            const UPLOADED_FILES = await zendeskUploadFiles(
              [newFile],
              null,
              pbmZendeskConfig
            )
            const GENERATED_FILE_TOKEN = await generateUploadFiles(
              UPLOADED_FILES
            )
            setFilesUploaded((fileList) => {
              let previousFilesUploaded = [...fileList]
              let newFileList = [
                ...previousFilesUploaded.filter(
                  (fileInFileList) => fileInFileList.path !== newFile.path
                ),
                {
                  ...newFile,
                  // path: null, // Note: this removes the path from the file after uploading to Zendesk
                  token: GENERATED_FILE_TOKEN[0],
                },
              ]
              if (fmr !== "encode")
                moduleDispatch({
                  type: "SAVE_DOCUMENTS",
                  payload: [...newFileList],
                })
              // if (callback) callback(newFileList)
              return newFileList
            })
            dispatch({
              type: "SHOW_TOAST",
              payload: {
                message: `${newFile?.oldname} has been uploaded.`,
                color: "success",
              },
            })
          }, 1000)
        }
      },
      "base64"
    )
}

export const hasFilesUploaded = (files) => {
  if (files.length > 0) return true
  else return false
}
